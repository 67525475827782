import { IStatus } from './order.model';

export interface IUserResponse {
  accessToken: string;
  refreshToken: string;
  user: IRUser;
}

export interface IUser {
  email: string;
  first_name: string;
  last_name: string;
  created_at: string;
  instagram: string;
  dob: Date;
  billing_phone: string;
}

export interface IRUser extends IUser {
  id: number;
  role: IUserRoles;
}

export interface IUserWithStatus {
  id: number;
  name: string;
  status?: IStatus;
  paid: boolean;
}

export enum IUserRoles {
  ADMIN = 'administrator',
  CUSTOMER = 'customer'
}

export interface ISendResetCode {
  email: string;
}

export interface IResetPassword {
  token: string;
  password: string;
  confirm: string;
}
