import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Descriptions, Divider, Input, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { AxiosResponse } from 'axios';
import { DeleteIcon } from 'components';
import dayjs from 'dayjs';
import useDebounce from 'hooks/useDebounce';
import { IKeys, IRUser } from 'models';
import { useState } from 'react';
import { userService } from 'services';

const pageSize = 10;

export const Students = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search);
  const client = useQueryClient();
  const { data, isPending } = useQuery({
    queryFn: () =>
      userService.getCustomers({
        search: debouncedSearch,
        per_page: pageSize,
        page
      }),
    queryKey: [IKeys.CUSTOMERS, { name: debouncedSearch, page }],
    staleTime: 1000 * 60
  });

  const remove = useMutation({
    mutationFn: (id: number) => userService.remove(id),
    onSuccess: (res) => {
      client.setQueryData(
        [IKeys.CUSTOMERS, { name: debouncedSearch, page }],
        (oldData: AxiosResponse<IRUser[]> | undefined) =>
          oldData && {
            ...oldData,
            data: oldData.data.filter((el) => el.id !== res.id)
          }
      );
    }
  });

  const columns: ColumnsType<IRUser> = [
    {
      title: 'Students',
      key: 'name',
      render: (_, el) => `${el.first_name} ${el.last_name}`
    },
    {
      title: 'DOB',
      key: 'dob',
      dataIndex: 'dob',
      render: (el) => dayjs(el).format('MM/DD/YY')
    },
    {
      title: 'Reg',
      key: 'reg',
      dataIndex: 'created_at',
      render: (el) => dayjs(el).format('MM/DD/YY')
    },
    {
      key: 'actions',
      dataIndex: 'id',
      render: (id, record) => (
        <DeleteIcon<IRUser>
          remove={remove}
          id={id}
          name={`${record.first_name} ${record.last_name}`}
        />
      )
    }
  ];

  return (
    <>
      <Input
        value={search}
        onChange={(val) => setSearch(val.target.value)}
        placeholder="Search by name"
      />
      <Divider />
      <Table
        dataSource={data?.data}
        columns={columns}
        loading={isPending}
        rowKey={(line) => line.id}
        size="small"
        expandable={{
          expandedRowRender: (el) =>
            el && (
              <Descriptions size="small">
                <Descriptions.Item label="Phone">
                  <Typography.Link href={`tel:${el.billing_phone}`}>
                    {el.billing_phone}
                  </Typography.Link>
                </Descriptions.Item>
                <Descriptions.Item label="Insta">
                  <Typography.Link
                    href={`https://instagram.com/${el.instagram}`}
                  >
                    {el.instagram}
                  </Typography.Link>
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  <Typography.Link href={`tel:${el.email}`}>
                    {el.email}
                  </Typography.Link>
                </Descriptions.Item>
              </Descriptions>
            ),
          expandRowByClick: true
        }}
        pagination={{
          current: page,
          pageSize,
          total: data?.headers['total'],
          onChange: (number) => setPage(number)
        }}
      />
    </>
  );
};
