import { App as AntApp, ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';

import App from './App';

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ConfigProvider>
    <AntApp>
      <App />
    </AntApp>
  </ConfigProvider>
);
