export const PROFILE = '/profile';
export const CLASSES = '/classes';
export const VIDEO = '/video';
export const PAYMENTS = '/payments';
export const LOGIN = '/login';
export const SIGN_UP = '/sign-up';
export const FORGOT_PASSWORD = '/forgot-password';
export const CART = '/cart';
export const COUPONS = '/coupons';
export const STUDENTS = '/students';
export const ORDERS = '/orders';
export const CALENDAR = '/calendar';
export const CHECKOUT = '/checkout';
export const REPORTS = '/reports';
export const ALL_COUPONS = '/all-coupons';
export const RESET_PASSWORD = '/reset-password/:token';
export const PRODUCT_CAT = '/product-categories';
